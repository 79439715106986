// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      const blockchain = store.getState().blockchain
      let mintedAmount = await store.getState().blockchain.smartContract.methods.mintedAmount().call();
      let maxSupply = await store.getState().blockchain.smartContract.methods.maxSupply().call()
      let paused = await store.getState().blockchain.smartContract.methods.paused().call();
      let cost = await store.getState().blockchain.smartContract.methods.cost().call();

      let viewCost = cost / 1000000000000000000;

      dispatch(
        fetchDataSuccess({
          mintedAmount,
          maxSupply,
          paused,
          cost,
          viewCost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
